import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { dateFormat } from '@/filter/date-format';

export class WorkOrderFlowEntityModel extends BaseEntityModel {
    @FormControl({
        label: '流程名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    processName: string = undefined;

    @FormControl({
        label: '流程标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true,
        pattern: /^[a-zA-z].*/,
        message: '标识符以字母开头'
    } as FormControlTextModel)
    processKey: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA,
        required: false
    } as FormControlTextModel)
    description: string = undefined;
    id: string = undefined;
    // deployed:boolean = true;
    deployedVersion: number = 1;
    version: number = 1;

    updateDate: any = undefined;
    createDate: any = undefined;
    deployDate: any = undefined;

    static getTableColumns() {
        return [
            {
                title: '流程名称',
                dataIndex: 'processName'
            },
            {
                title: '流程标识符',
                dataIndex: 'processKey'
            },
            {
                title: '部署版本',
                dataIndex: 'deployedVersion'
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '修改时间',
                dataIndex: 'updateDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '部署时间',
                dataIndex: 'deployDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 400
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderFlowQueryModel extends QueryPageModel {
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
