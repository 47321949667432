
import { WorkOrderFlowEntityModel, WorkOrderFlowQueryModel } from '@/entity-model/work-order-flow-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, put } from './request';
import axios from 'axios';
import { UserStoreModule } from '@/store/modules/user';

const DEFAULT_REQUEST_HEADERS = {
    'content-type': 'application/json;charset=UTF-8'
};

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/process-model`;

class WorkOrderFlowService implements ICRUDQ<WorkOrderFlowEntityModel, WorkOrderFlowQueryModel> {
    async create(model: WorkOrderFlowEntityModel):Promise<WorkOrderFlowEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderFlowEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderFlowEntityModel().toModel(res);
    }

    async update(model: WorkOrderFlowEntityModel):Promise<WorkOrderFlowEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderFlowEntityModel):Promise<WorkOrderFlowEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderFlowQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;

        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // res.items = _.map(res.items, item => item = new WorkOrderFormQueryModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderTypeEntityModel().toModel(item));
    }

    async saveXML(params:any):Promise<any> {
        const url = `${URL_PATH}/saveXml`;
        const res = await post(url, params);
        return res;
    }

    async getFlowDetail(flowId:string):Promise<any> {
        return new Promise((resolve, reject) => {
            axios.create().request(
                {
                    url: `${WORKORDER_BASE_REQUEST_PATH}/process-model/bpmn/file/${flowId}`,
                    method: 'get',
                    headers: Object.assign({}, DEFAULT_REQUEST_HEADERS, { 'X-Authorization': `Bearer ${UserStoreModule.Token}` })
                }
            )
                .then(response => {
                    if (!response || response['status'] !== 200) {
                        reject(response.statusText);
                    } else {
                        resolve(response.data);
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    reject(error);
                });
        });
    }

    async deploy(flowId:string):Promise<any> {
        const url = `${URL_PATH}/deploy/${flowId}`;
        const res = await post(url);
        return res;
    }

    async preview(flowId:string):Promise<any> {
        const url = `${URL_PATH}/image/${flowId}`;
        const res = await get(url);
        return res;
    }
}

export default new WorkOrderFlowService();
